import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import axios from "../../api/axios";

import {
  Bars3Icon,
  ExclamationTriangleIcon,
  HomeModernIcon,
  NewspaperIcon,
  ShieldCheckIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, Outlet } from "react-router-dom";
import Logo from "../../components/images/LOGO.png";
import Sentralstovsuger from "../images/sentralstovsoger.jpg";
import Aggregat from "../images/aggregat.jpg";
import Reservedeler from "../images/reservedeler.jpg";
import Filter from "../images/filter.jpg";
import Kjokkenhette from "../images/kjokkenhette.jpg";
import Kanaldeler from "../images/kanaldeler.jpg";
import { toast } from "react-hot-toast";
import CustomerPopover from "../utils/CustomerPopover";
import ShoppingCart from "../utils/ShoppingCart";
import Breadcrumbs from "../utils/breadcrumbs";
import { ResourceLoading } from "../utils/ResourceLoading";
import { categoryPath } from "../utils/Utils";

const solutions = [
  {
    name: "Radon",
    description: "Alt innen måling og radonsanering",
    href: "/tjenester/radon",
    icon: ExclamationTriangleIcon,
  },
  {
    name: "Montering",
    description:
      "Spar på helse, miljø og utgifter. Skaff deg balansert ventilasjon idag",
    href: "/tjenester/ventilasjon",
    icon: HomeModernIcon,
  },
  {
    name: "Filterservice",
    description: "Kontroll, rengjøring av aggregat og bytte av filter",
    href: "/tjenester/filterservice",
    icon: ShieldCheckIcon,
  },
  {
    name: "Full service",
    description: "Kontroll, rengjøring og inn-regulering",
    href: "/tjenester/full-service",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Kontroll av ventilasjon",
    description: "Your customers' data will be safe and secure.",
    href: "/tjenester/kontroll",
    icon: NewspaperIcon,
  },
  {
    name: "Reparasjon",
    description: "Vi hjelper deg med reparasjon uansett modell og merke",
    href: "/tjenester/reparasjon",
    icon: WrenchIcon,
  },
];

const products = [
  {
    name: "Reservedeler",
    path: "/produkter/reservedeler",
    description: "",
    href: "#",
    img: Reservedeler,
    icon: ShieldCheckIcon,
  },
  {
    name: "Filter",
    path: "/produkter/filter",
    description: "",
    href: "#",
    img: Filter,
    icon: ShieldCheckIcon,
  },
  {
    name: "Kjøkkenhetter",
    path: "/produkter/kjokkenhetter",
    description: "",
    href: "#",
    img: Kjokkenhette,
    icon: ShieldCheckIcon,
  },
  {
    name: "Avtrekksvifter",
    path: "/produkter/avtrekksvifter",
    description: "",
    href: "#",
    img: Sentralstovsuger,
    icon: ShieldCheckIcon,
  },
  {
    name: "Aggregat",
    path: "/produkter/aggregat",
    description: "",
    href: "#",
    img: Aggregat,
    icon: ShieldCheckIcon,
  },
  {
    name: "Kanaldeler",
    path: "/produkter/kanaldeler",
    description: "",
    href: "#",
    img: Kanaldeler,
    icon: ShieldCheckIcon,
  },
  {
    name: "Tilbehør",
    path: "/produkter/tilbehor",
    description: "",
    href: "#",
    img: Kanaldeler,
    icon: ShieldCheckIcon,
  },
];

const resources = [
  {
    name: "Kontakt oss",
    description: "Kontaktinformasjon, adresse og mer",
    href: "/kontakt",
  },
  { name: "Om oss", description: "Vår lille historie", href: "/om-oss" },
  { name: "Hjelp & FAQ", description: "Alt du trenger å vite", href: "/hjelp" },
  {
    name: "Prosjekter",
    description: "Om våre prosjekter og referanser fra kunder",
    href: "/om-oss/prosjekter",
  },
  {
    name: "Ledige Stillinger",
    description: "Våre ledige stillinger?",
    href: "/stillinger/",
  },
  {
    name: "Ansatte",
    description: "Hvem er våre ansatte?",
    href: "/om-oss/ansatte",
  },
];

const social = [
  {
    name: "Twitter",
    href: "#",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    name: "Instagram",
    href: "#",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeLayout({ children }) {
  const [state, setState] = useState({
    isLoading: false,
    error: false,
    errors: null,
  });

  const [categories, setCategories] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getCategories = () => {
    setState({ loading: true });
    setCategoriesLoading(true);

    const request = {
      parents: true,
    };

    axios
      .get(`/products/categories/list?parents=true`)
      .then((response) => {
        setState({ loading: false, error: false });
        setCategories(response.data.data);
        setCategoriesLoading(false);
      })
      .catch((e) => {
        setState({ loading: false, error: true });
        setCategoriesLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getCategories();
    }, 1000);
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <div className="">
      <div>
        <Popover className="relative">
          <div className="flex items-center justify-between p-6 md:justify-start md:space-x-10 shadow">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <span className="sr-only">Airon AS</span>
                <img
                  className="h-10 w-auto sm:h-14"
                  src={Logo}
                  alt="Airon AS Logo"
                />
              </Link>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Åpne meny</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              <Popover className="relative rounded-lg p-3 hover:bg-gray-50">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                      )}
                    >
                      <span>Tjenester</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform lg:left-1/2 lg:ml-0 lg:max-w-2xl lg:-translate-x-1/2">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                            {solutions.map((solution) => (
                              <Link
                                key={solution.name}
                                to={solution.href}
                                className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                              >
                                <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-blue-400 text-white sm:h-12 sm:w-12">
                                  <solution.icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {solution.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {solution.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                          {/* <div className="bg-gray-50 p-5 sm:p-8">
                            <a href="#" className="-m-3 flow-root rounded-md p-3 hover:bg-gray-100">
                              <div className="flex items-center">
                                <div className="text-base font-medium text-gray-900">Enterprise</div>
                                <span className="ml-3 inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium leading-5 text-indigo-800">
                                  New
                                </span>
                              </div>
                              <p className="mt-1 text-sm text-gray-500">
                                Empower your entire team with even more advanced tools.
                              </p>
                            </a>
                          </div> */}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Link
                to={"/produkter/reservedeler"}
                className="flex items-start rounded-lg p-3 hover:bg-gray-50"
              >
                Reservedeler
              </Link>

              <Popover className="relative rounded-lg p-3 hover:bg-gray-50">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                      )}
                    >
                      <span>Produkter</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform lg:left-1/2 lg:ml-0 lg:max-w-2xl lg:-translate-x-1/2">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                            {categories.map((category) => (
                              <Link
                                key={category.title}
                                to={categoryPath(category)}
                                className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                              >
                                <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md border-0 border-blue-300 text-white sm:h-12 sm:w-12">
                                  {category?.image?.id ? (
                                    <img
                                      src={category?.image?.file?.thumb?.url}
                                      alt={category.title}
                                    />
                                  ) : category.icon ? (
                                    <category.icon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : null}
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {category.title}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {category.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                            <Link
                              to="/produkter"
                              className="text-base font-medium text-blue-400 hover:border-b-2 hover:border-blue-400 "
                            >
                              Se alle produkter
                              <span aria-hidden="true"> &rarr;</span>
                            </Link>
                          </div>
                          {/* <div className="bg-gray-50 p-5 sm:p-8">
                            <a href="#" className="-m-3 flow-root rounded-md p-3 hover:bg-gray-100">
                              <div className="flex items-center">
                                <div className="text-base font-medium text-gray-900">Enterprise</div>
                                <span className="ml-3 inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium leading-5 text-indigo-800">
                                  New
                                </span>
                              </div>
                              <p className="mt-1 text-sm text-gray-500">
                                Empower your entire team with even more advanced tools.
                              </p>
                            </a>
                          </div> */}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Popover className="relative rounded-lg p-3 hover:bg-gray-50">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                      )}
                    >
                      <span>Om oss</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {resources.map((resource) => (
                              <Link
                                key={resource.name}
                                to={resource.href}
                                className="-m-3 block rounded-md p-3 hover:bg-gray-50"
                              >
                                <p className="text-base font-medium text-gray-900">
                                  {resource.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {resource.description}
                                </p>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>

            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              <ShoppingCart />
              <CustomerPopover />
              {/* <a onClick={() => toast.error('Denne funksjonen kommer snart!')} className="cursor-pointer whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                Logg inn
              </a>
              <a
                onClick={() => toast.error('Denne funksjonen kommer snart!')}
                className="cursor-pointer  ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-blue-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
              >
                Registrer deg
              </a> */}
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
            >
              <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="px-5 pt-5 pb-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={Logo} alt="Airon AS" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>

                  <div className="mt-6">
                    <Link
                      onClick={() => handleToggle()}
                      to="/produkter"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                    >
                      Produkter
                    </Link>
                  </div>
                  <div className="mt-6">
                    {categoriesLoading ? (
                      <ResourceLoading />
                    ) : (
                      <nav className="grid grid-cols-1 gap-7">
                        {solutions.map((solution) => (
                          <Link
                            key={solution.name}
                            to={solution.href}
                            className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                          >
                            <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-blue-400 text-white">
                              <solution.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-4 text-base font-medium text-gray-900">
                              {solution.name}
                            </div>
                          </Link>
                        ))}
                      </nav>
                    )}
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    {resources.map((resource) => (
                      <a
                        key={resource.name}
                        href={resource.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {resource.name}
                      </a>
                    ))}
                  </div>
                  {/* <div className="mt-6">
                    <Link
                      href="#"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                    >
                      Registrer deg
                    </Link>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Eksisterende kunde?{" "}
                      <Link
                        href="#"
                        className="text-indigo-600 hover:text-indigo-500"
                      >
                        Logg inn
                      </Link>
                    </p>
                  </div> */}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>

      <main className="relative isolate h-full">
        <Breadcrumbs />
        {children}
      </main>

      <footer className="border-t border-gray-100 py-6 sm:py-10">
        <div className="mx-auto flex max-w-7xl flex-row items-center justify-center gap-8 px-6 sm:flex-row lg:px-8">
          <p className="text-sm leading-7 text-gray-400">
            &copy; {currentYear} - Airon AS
          </p>
          <p className="hidden sm:block text-xs leading-7 text-gray-400 border-2 rounded-md p-2 border-blue-200">
            Developed & Managed by Christoffer Skaar
          </p>
          <div className="hidden sm:block sm:h-7 sm:w-px sm:flex-none sm:bg-gray-200" />
          <div className="flex gap-x-4">
            {social.map((item, itemIdx) => (
              <a
                key={itemIdx}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </footer>
    </div>
  );
}
