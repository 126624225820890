import { useCallback, useEffect, useState } from "react";
import { fetchDocuments } from "../../api/documents";
import { imgPlaceholder } from "../utils/Utils";
import { Link } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";

export const DocumentStacklist = ({ module, moduleParam, styleType }) => {
  const [documents, setDocuments] = useState([]);

  const [controller, setController] = useState({
    page: 1,
    per_page: 10,
    sort: "desc",
    sortBy: "created_at",
    query: "",
    view: "list",
    moduleParam: moduleParam,
    module: module,
  });

  const getDocuments = async () => {
    await fetchDocuments(controller).then((res) => {
      console.log(res);
      setDocuments(res?.data?.data);
    });
  };

  useEffect(() => {
    getDocuments();
  }, []);

  function validateDocument(document) {
    // If pdf, return imgPlaceholder
    if (document?.file?.filename?.includes(".pdf")) {
      return imgPlaceholder;
    }

    // If thumb url is available, return thumb url
    if (document?.file?.thumb?.url) {
      return document?.file?.thumb?.url;
    }

    // If thumb url is not available, return imgPlaceholder
    return imgPlaceholder;
  }

  const Document = ({ document }) => {
    return (
      <div
        key={document.id}
        className="flex items-center justify-between mt-5 w-full p-4 bg-white rounded-lg shadow-md"
      >
        <div className="flex">
          <div className="h-25 w-25 flex-shrink-0">
            <img
              className="h-48 w-full object-cover transition delay-150 duration-300 ease-in-out"
              src={validateDocument(document)}
              alt=""
            />
          </div>
          <div className="flex left flex-col gap-1 px-4">
            <h2 className="text-lg font-bold">
              {document.title || "Missing title"}
            </h2>

            <p className="text-xs italic">{document?.file?.filename}</p>

            <span className="text-xs text-gray-500">
              {document.created_at || "Missing date"}
            </span>

            <span className="mt-5">
              <Link
                to={document?.file?.url}
                target="_blank"
                className="text-xs text-blue-500"
              >
                Last ned
              </Link>
            </span>
          </div>
        </div>
        <div className="flex gap-2"></div>
      </div>
    );
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  function documentSrc(document, preferedType = "thumb") {
    // check if filename ends with .pdf or .PDF

    if (document?.file?.filename?.toLowerCase().endsWith(".pdf")) {
      return imgPlaceholder;
    }

    if (preferedType === "thumb") {
      return document?.file?.thumb?.url
        ? document?.file?.thumb.url
        : imgPlaceholder;
    } else if (!document?.id) {
      return imgPlaceholder;
    } else {
      return document?.file?.url ? document?.file?.url : imgPlaceholder;
    }
  }

  const images = documents?.map((document) =>
    documentSrc(document, "original")
  );

  const DocumentGrid = ({ index, document }) => {
    return (
      <div
        key={index}
        onClick={() => openImageViewer(index)}
        className=" bg-white rounded-lg shadow-md"
      >
        <div className="h-25 w-25 flex-shrink-0">
          <img
            className="h-48 w-full object-cover transition delay-150 duration-300 ease-in-out"
            src={validateDocument(document)}
            alt=""
          />
        </div>
      </div>
    );
  };

  if (styleType === "grid")
    return (
      <>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-5 ">
          {documents &&
            documents?.map((document, index) => {
              return (
                <DocumentGrid key={index} index={index} document={document} />
              );
            })}
        </div>

        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
          />
        )}
      </>
    );

  if (styleType === "stacklist")
    return (
      <div className="px-2 py-2">
        {documents &&
          documents?.map((document, index) => {
            return <Document key={index} document={document} />;
          })}
      </div>
    );
};

DocumentStacklist.defaultProps = {
  styleType: "stacklist",
};
