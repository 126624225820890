import axios from "./axios";

export async function fetchDocuments(controller) {
  return axios({
    method: "get",
    url: "/cdb/documents",
    params: controller,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}
